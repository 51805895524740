export const themeConsts = {
    primaryBlue: '#006cff',
    secondaryBlue: '#7f9aff',
    activeBlue: '#4e71f4',
    lightBlue: '#00e5ff',
    thirdBlue: '#00B1FF',
    orange: '#F7AB00',
    green: '#1de9b6',
    lightGreen: '#d2fbf0',
    purple: '#651fff',
    yellow: '#ffeb3b',
    pink: '#f50057',
    red: '#F43D3D',
    white: '#ffffff',
    lightGrey: '#f5f7fd',
    titlesDarkGrey: '#4b4b4b',
    textDarkGrey: '#797979',
    textDarkerGrey: '#707070',
    textLightGrey: '#aaaaaa',
    textLighterGrey: '#BEBEBE',
    placeholderGrey: '#6e6e6e',
    bordersGrey: '#ececec',
    mainShadow: '0px 1px 6px rgba(0,0,0, .16)',
    activeShadow: '0px 3px 6px rgba(0,0,0,.16)',
    borderRadius:'10px',
    tableHeader: '#DFE4F8',
    tableColor1: '#eef1ff',
    tableColor2: '#f5f7ff',
    tableListBorder: '#E6E6E6',
    notificationsBg: '#f0f3fd'
}